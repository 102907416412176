import React from "react"
import { Box, Link, Text, Flex, Button } from "@chakra-ui/react"
import {
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaAt,
} from "react-icons/fa"

const ContactUs = () => {
  const textColor = "white"

  return (
    <Flex
      color="white"
      pb={[20, 20, 0, 0]}
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      margin={0}
      w={"45%"}
      id={"#Contact"}
      h={"50%"}
    >
      <Text
        textAlign={"center"}
        fontSize={["lg"]}
        fontWeight={800}
        color={textColor}
        pb={10}
      >
        [insert logo]
      </Text>
      <Flex
        direction={["column", "column", "column", "column"]}
        justifyContent={"space-around"}
        alignItems={"center"}
        h={"100%"}
        w={"100%"}
      >
        <Flex
          padding={5}
          h={"100%"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          w={"300px"}
        >
          <Link _hover={{ textDecoration: "none" }}>
            <FaInstagram size={"40px"} />
          </Link>
          <Link _hover={{ textDecoration: "none" }}>
            <FaFacebookF size={"35px"} />
          </Link>
          <Link _hover={{ textDecoration: "none" }}>
            <FaTwitter size={"35px"} />
          </Link>
        </Flex>
        <Flex
          padding={5}
          h={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          w={"300px"}
        >
          <Link pb={5} href={"#number"}>
            {" "}
            <FaPhoneAlt size={"35px"} />
          </Link>
          <Text fontSize={["xs", "sm"]}>(M-F, 9am-6pm CST)</Text>
          <Text fontSize={["sm", "sm"]}>Toll Free: </Text>
          <Text fontSize={["sm", "sm"]}>1-800-555-5555</Text>
        </Flex>
        <Flex
          padding={5}
          h={"100%"}
          flexDirection={"column"}
          alignItems={"center"}
          w={"300px"}
        >
          <Box pb={5}>
            {" "}
            <FaAt size={"35px"} />{" "}
          </Box>

          <Button color="black">Send Email</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ContactUs
