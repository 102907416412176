import { extendTheme, useColorModeValue } from "@chakra-ui/react";
import "@fontsource/inter";
import { mode } from "@chakra-ui/theme-tools";
import { ThemeConfig } from "@chakra-ui/react";

const fonts = {
  body: "Inter",
};

const fontSizes = {
  "2xs": "12px",
  xs: "14px",
  sm: "16px",
  md: "22px",
  lg: "27px",
  xl: "44px",
  "2xl": "48px",
  "3xl": "62px",
  "4xl": "80px",
};

const colors = {
  gray: {
    50: "#171f2b",
    100: "#e0e0e0",
    200: "#cccccc",
    300: "#b8b8b8",
    400: "#a3a3a3",
    500: "#8f8f8f",
    600: "#7a7a7a",
    700: "#666666",
    800: "#525252",
    900: "#333333",
  },
  bgColor: "#171f2b",
  white: "#ffffff",
  black: "#242424",
  transparent: "transparent",
};

interface GlobalProps {
  colorMode: string;
  theme: Record<string, unknown>;
}

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: "dark",
};

const styles = {
  global: ({ colorMode }: GlobalProps) => ({
    body: {
      backgroundColor: colorMode === "dark" ? "gray.900" : "white",
      overscrollBehavior: "none",
    },
  }),
};

const breakpoints = ["30em", "48em", "62em", "80em", "92em"];

const components = {
  Input: {
    variants: {
      flushed: {
        bg: "transparent",
      },
    },
    defaultProps: {
      size: "sm",
      variant: "flushed",
      focusBorderColor: "black",
    },
  },
  Textarea: {
    variants: {
      flushed: {
        bg: "transparent",
      },
    },
    defaultProps: {
      size: "sm",
      variant: "flushed",
      focusBorderColor: "black",
    },
  },

  Heading: {
    baseStyle: {
      color: "black",
    },
    sizes: {
      xl: {
        fontSize: "xl",
      },
    },
    defaultProps: {
      size: "xl",
    },
  },
  Button: {
    baseStyle: {
      fontWeight: "400",
    },
    variants: {
      brand: {
        bg: "gray.100",
        _hover: {
          bg: "brand.400",
          textColor: "white",
        },
        _active: {
          bg: "brand.600",
          textColor: "white",
        },
      },
    },
    sizes: {
      md: {
        fontSize: "sm",
      },
    },
    defaultProps: {},
  },
};

export const theme = extendTheme({
  fonts: fonts,
  fontSizes: fontSizes,
  colors: colors,
  breakpoints: breakpoints,
  components: components,
});
