import React, { useState } from "react";
import {
  Flex,
  Image,
  Box,
  UnorderedList,
  ListItem,
  Text,
  Spacer,
  Link,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import Img from "~images/Icons/icon.png";
const NavBar = () => {
  const { toggleColorMode, colorMode } = useColorMode();

  const [isClicked, setIsClicked] = useState(true);
  const changeState = () => {};
  const bgColor = useColorModeValue("white", "#171f2b");
  const textColor = useColorModeValue("#171f2b", "white");

  const Links = [
    {
      title: "HOW IT WORKS",
      link: "#howItWorks",
    },
    { title: "BLOG", link: "http://localhost:8000/blog-page" },
    { title: "FAQs", link: "http://localhost:8000/faqs-page" },
    { title: "CONTACT", link: "#Contact" },
  ];

  return (
    <Box
      bg={bgColor}
      h={"100px"}
      w={"100%"}
      margin={0}
      display={["none", "none", "block", "block"]}
    >
      <Flex
        direction={"row"}
        justify={"center"}
        alignContent={"center"}
        w={"80%"}
        m={"auto"}
        h={"100%"}
      >
        <Flex direction={"row"} alignItems={"center"} height={"100%"}>
          {" "}
          <Link href={"http://localhost:8000/"}>
            <Image
              src={`${Img}`}
              w={"67px"}
              h={"67px"}
              mr={"10px"}
              _hover={{ transform: "rotate(180deg" }}
            />
          </Link>
          <Text padding={"5px"} fontSize={"lg"} fontWeight={700}>
            Cypher
          </Text>
        </Flex>
        <Spacer />
        <Flex>
          <UnorderedList
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            listStyleType={"none"}
          >
            {Links.map((data) => (
              <ListItem p={4}>
                <Link
                  fontSize={"sm"}
                  color={textColor}
                  href={data.link}
                  _hover={{
                    textDecoration: "none",
                    color: "#f26d3c",
                    transition: "all 0.4s ease",
                  }}
                  _focus={{ textDecoration: "underline" }}
                >
                  {data.title}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
        <Flex
          direction={"row"}
          height={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          ml={"10px"}
          width={"5%"}
        >
          <Link _hover={{ color: "#f26d3c" }}>
            {" "}
            <FaFacebookF pr={5} size={"20px"} />
          </Link>
          <Link _hover={{ color: "#f26d3c" }}>
            {" "}
            <FaInstagram size={"24px"} />{" "}
          </Link>
        </Flex>
        <Flex h={"100%"} alignItems={"center"} ml={5}>
          <Button
            onClick={() => toggleColorMode()}
            _focus={{ outline: "none" }}
          >
            {" "}
            Toggle {colorMode === "light" ? "Dark" : "Light"}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavBar;
