import React, { useState } from "react"
import { FormControl, Box, Text, Flex, Input, Button } from "@chakra-ui/react"

import firebase from "gatsby-plugin-firebase"

const NewsletterForm = () => {
  const textColor = "white"

  var db = firebase.firestore()
  const [fn, setFN] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState(" ")
  function addEmail() {
    db.collection("newsletter")
      .doc(email)
      .set({ name: fn, email: email })
      .then((docRef) => {})
      .catch((error) => {})
  }

  return (
    <Box
    //  h={"45vh"}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        m={"auto"}
        alignItems={"center"}
        zIndex={1}
      >
        <Text
          color={textColor}
          fontSize={["sm", "lg"]}
          textAlign={"center"}
          fontWeight={800}
          w={"100%"}
        >
          STAY IN THE LOOP
        </Text>
        <Box padding={5}>
          <FormControl>
            <Input
              placeholder={"First Name"}
              type="text"
              value={fn}
              onChange={(e) => setFN(e.target.value)}
              size={"sm"}
              color={textColor}
            ></Input>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <Input
              type={"email"}
              placeholder={"E-mail address"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Input>
          </FormControl>
        </Box>
        <Button
          w={["75%", "30%"]}
          background={textColor}
          borderRadius={["none", "30px"]}
          mt={4}
          color="black"
          onClick={addEmail}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  )
}

export default NewsletterForm
