import React, { useEffect, useState } from "react"
import firebase from "gatsby-plugin-firebase"

import { User } from "~models/user"

export const UserContext = React.createContext<UserProviderType>({
	user: null,
	isLoading: true,
	userId: "",
})

type UserProviderType = {
	user: User | null
	isLoading: boolean
	userId: string
}

export const UserProvider = ({ children }) => (
	<UserContext.Provider value={{ ...useUser() }}>
		{children}
	</UserContext.Provider>
)

function useUser(): UserProviderType {
	const [{ user, isLoading }, setUser] = useState({
		user: null,
		isLoading: true,
	})
	const [userId, setUserId] = useState("")
	const [firebaseReady, setFirebaseReady] = useState(false)

	useEffect(() => {
		if (!firebase) return
		setFirebaseReady(true)
	}, [firebase])

	useEffect(() => {
		if (!firebaseReady) return
		let isMounted = true
		firebase.auth().onAuthStateChanged((userAuth) => {
			if (isMounted) {
				if (userAuth) setUserId(userAuth.uid)
			}
		})
		return () => {
			isMounted = false
			return
		}
	}, [firebaseReady])

	useEffect(() => {
		if (!firebaseReady) return
		let isMounted = true
		if (firebase.auth().currentUser && firebase.auth().currentUser.uid) {
			if (isMounted) {
				setUserId(firebase.auth().currentUser.uid)
			}
		}
		return () => {
			isMounted = false
			return
		}
	}, [firebaseReady])

	useEffect(() => {
		if (!userId || !firebaseReady) return
		firebase
			.firestore()
			.collection("Users")
			.doc(userId)
			.onSnapshot((doc) => {
				setUser({ user: doc.data(), isLoading: false })
			})
	}, [userId, firebaseReady])

	return {
		user,
		isLoading,
		userId,
	}
}
