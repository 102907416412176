import React from "react"
import {
  Box,
  Link,
  Text,
  UnorderedList,
  ListItem,
  Flex,
} from "@chakra-ui/react"
import NewsletterForm from "~components/footer/newsletter-form"
import ContactUs from "./contact-us"

const CypherNewsletter = () => {
  const bgColor = "#171F2B"
  const textColor = "white"

  const first = [
    { title: "About" },
    { title: "Privacy Policy" },
    { title: "Terms & Conditions" },
  ]

  const Explore = [
    { title: "How it Works", link: "" },
    { title: "Blog", link: "" },
    { title: "FAQs", link: "" },
    { title: "Contact", link: "" },
  ]

  return (
    <Box background={bgColor} margin={0} w={"100%"} h={"100%"} color="white">
      <Flex
        direction={["column", "column", "row"]}
        padding={10}
        justifyContent={["center"]}
        alignItems={["center", "center", "center"]}
        w={["100%"]}
      >
        <ContactUs />
        <Flex
          w={["100%", "50%"]}
          direction={["column", "column", "row"]}
          justifyContent={"center"}
          alignItems={["center", "center", "flex-start"]}
          pb={[20, 20, 0, 0]}
        >
          <UnorderedList listStyleType={"none"} mr={[0, 0, 10]}>
            <ListItem>
              <Text
                color={textColor}
                fontWeight={800}
                fontSize={"md"}
                textAlign={"center"}
              >
                EXPLORE
              </Text>{" "}
            </ListItem>
            {Explore.map((data) => {
              return (
                <ListItem color={textColor} p={[1, 1, 3]} pt={2} pb={2}>
                  <Link>
                    <Text color={textColor} textAlign={"center"}>
                      {data.title}
                    </Text>
                  </Link>
                </ListItem>
              )
            })}
          </UnorderedList>

          <UnorderedList listStyleType={"none"} pt={[2, 4, 0]}>
            <ListItem>
              <Text
                color={textColor}
                fontWeight={800}
                fontSize={"md"}
                textAlign={"center"}
              >
                SUPPORT
              </Text>
            </ListItem>
            {first.map((data) => (
              <ListItem color={textColor} p={[1, 1, 3]} pt={2} pb={2}>
                <Link>
                  <Text color={textColor} textAlign={"center"}>
                    {data.title}
                  </Text>
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        </Flex>
        <Box w={["100%", "50%"]} h={"100%"} pt={[8, 8, 0]}>
          <NewsletterForm />
        </Box>
      </Flex>
    </Box>
  )
}

export default CypherNewsletter
