import React from "react"
import NavBar from "~components/shared/navigation/nav-bar"
import MobileNav from "~components/shared/navigation/mobile-nav"
import CypherNewsletter from "~components/footer/cypher-newsletter"
import { Flex } from "@chakra-ui/react"

type LayoutProps = {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Flex direction={"column"} minH="100vh" overflowX={"hidden"}>
      <NavBar />
      <MobileNav />
      {children}
      <CypherNewsletter />
    </Flex>
  )
}

export default Layout
