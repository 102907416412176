import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  UnorderedList,
  ListItem,
  Image,
  Spacer,
  Link,
  useColorMode,
  useColorModeValue,
  ScaleFade,
} from "@chakra-ui/react";
import { FaGripLines, FaTimes, FaFacebookF, FaInstagram } from "react-icons/fa";
import Img from "~images/Icons/icon.png";
const MobileNav = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("white", "#171F2B");
  const textColor = useColorModeValue("#171F2B", "white");

  const [isOpen, setIsOpen] = useState(false);

  const Links = [
    {
      title: "HOW IT WORKS",
      link: "#",
    },
    { title: "BLOG", link: "#" },
    { title: "FAQs", link: "#" },
    { title: "CONTACT", link: "#" },
  ];

  return (
    <>
      <Box
        display={["block", "block", "none", "none"]}
        h={"100px"}
        // background={isOpen ? "rgba(23, 31, 43, 0.3)" : "#171f2b"}
        background={bgColor}
        w={"100%"}
        margin={0}
      >
        <Flex
          direction={"row"}
          h={"100%"}
          alignItems={"center"}
          w={["56%", "52%"]}
          padding={0}
        >
          {" "}
          <Button
            background={"transparent"}
            _active={{ background: "none", outline: "none" }}
            _focus={{ background: "none", outline: "none" }}
            zIndex={4}
            leftIcon={
              isOpen ? (
                <FaTimes
                  size={"27px"}
                  color={textColor}
                  transition={"all 0.3s ease"}
                />
              ) : (
                <FaGripLines
                  size={"27px"}
                  color={textColor}
                  transition={"all 0.3s ease"}
                />
              )
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {" "}
          </Button>
          <Spacer />
          <Link>
            {" "}
            <Image src={`${Img}`} h={"50px"} w={"50px"} padding={0} />
          </Link>
        </Flex>
      </Box>

      {isOpen && (
        <ScaleFade initialScale={0.9} in={isOpen}>
          <Flex
            h={"100vh"}
            position={"fixed"}
            top={0}
            left={0}
            w={"100%"}
            backgroundColor={"#d9d9d9"}
            direction={"column"}
            zIndex={3}
          >
            <Flex
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={3}
            >
              <UnorderedList listStyleType={"none"}>
                {Links.map((data) => (
                  <ListItem
                    fontSize={["lg", "xl", "2xl"]}
                    textAlign={"center"}
                    p={5}
                  >
                    <Link
                      href={data.link}
                      _hover={{ textDecoration: "none" }}
                      _focus={{
                        textDecoration: "none",
                        color: "#f26d3c",
                        outline: "none",
                      }}
                      _active={{
                        textDecoration: "none",
                        transform: "scale(2,2)",
                        color: "#f26dc3",
                        outline: "none",
                        backgroundColor: "none",
                        fontSize: "xs",
                      }}
                    >
                      {" "}
                      {data.title}
                    </Link>
                  </ListItem>
                ))}
              </UnorderedList>
              <Button
                onClick={() => toggleColorMode()}
                _focus={{ outline: "none" }}
              >
                {" "}
                Toggle {colorMode === "light" ? "Dark" : "Light"}
              </Button>
            </Flex>
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={0.5}
            >
              <Box mr={"15px"}>
                {" "}
                <Link _active={{ color: "#f26dc3" }}>
                  {" "}
                  <FaFacebookF size={"32px"} />
                </Link>
              </Box>
              <Box>
                {" "}
                <Link _active={{ color: "#f26dc3" }}>
                  {" "}
                  <FaInstagram size={"35px"} />
                </Link>
              </Box>
            </Flex>
          </Flex>
        </ScaleFade>
      )}
    </>
  );
};

export default MobileNav;
